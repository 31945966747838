@import "variables.scss";

footer {
	padding: 32px 64px;

	background-color: black;
	color: white;

	font-weight: 400;
	font-size: 14px;
	line-height: 21px;

	display: grid;

	grid-template-columns: 1fr;
	grid-template-areas: 	"social"
							"links"
							"copyright";


	@media screen and (min-width: $BreakpointSM) {
		grid-template-columns: auto auto auto;
		grid-template-areas: "copyright links social";
	}

	text-align: center;

	a {
		color: white;
	}

	.copyright {
		grid-area: copyright;
		@media screen and (min-width: $BreakpointSM) {
			margin: 12px auto;
		}
	}

	.links {
		grid-area: links;
	}

	.social {
		grid-area: social;
	}

	ul {
		list-style-type: none;

		margin: 0;
		padding: 0;

		li {
			margin-bottom: 12px;
		}

		&.links {
			margin: 12px auto;
			@media screen and (min-width: $BreakpointSM) {
				display: flex;
				li {
					margin-right: 30px;
					&:last-of-type {
						margin-right: 0;
					}
				}
			}
		}

		&.social {
			display: flex;
			margin: 12px auto;
			li {
				margin: 0 20px 0 0;
				&:last-of-type {
					margin-right: 0;
				}
			}
		}
	}
}