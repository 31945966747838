@import "../../styles/variables.scss";

.subscribe-form {
	text-align: left;
	color: black;

	.-error {
		color: #990000;
	}
	
	.form-input {
		margin-bottom: 24px;

		label {
			display: block;
			font-weight: 700;
			font-size: 16px;
			line-height: 24px;
		}
		input {
			display: block;
			width: 100%;
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;

			background-color: #ECECEC;
			border: 0;
			border-radius: 8px;
			padding: 8px;
		}
	}

	.form-checkbox {
		margin-bottom: 24px;
	}

	.form-submit {
		input {
			width: 100%;
			border: 0;
			border-radius: 8px;
			padding: 8px;

			background-color: black;
			color: white;
			text-align: center;

			font-weight: 700;
			font-size: 14px;
			line-height: 21px;
		}
	}
}