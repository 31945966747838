@import "../../styles/variables.scss";
@import "./subscribe-form.scss";

.home {
	margin: 64px 70px;

	@media screen and (min-width: $BreakpointSM) {
		max-width: 484px;
		margin: 64px auto;	
	}

	text-align: center;

	color: #5a5a5a;	
}