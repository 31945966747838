@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

@import "variables.scss";
@import "footer.scss";

* {
    box-sizing: border-box;
}

html {
	height: -webkit-fill-available;
}

body {
	margin: 0;
	height: -webkit-fill-available;
	font-family: 'Poppins', sans-serif;
}

#root {
	min-height: 100vh;
	min-height: -webkit-fill-available;

	display: grid;
	grid-template-rows: auto max-content;
}

h1 {
	font-weight: 600;
	font-size: 36px;
	line-height: 54px;
}

.body {
	height: 100%;
}